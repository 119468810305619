@import '../../vars.scss';

.custom-dialog-container {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 14px;
    border: 1px solid $border-default;
  }

  &.slide-dialog {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      transform-origin: bottom center;
      animation: slideIn 300ms ease-out;
    }
  }

  &--no-shadow {
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      box-shadow: none;
    }
  }

  .mdc-dialog__title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
    border-bottom: 1px solid $border-default;
    margin: 0;

    &::before {
      display: none;
    }
  }

  .mdc-dialog .mdc-dialog__content {
    padding: 25px !important;
    background: $background-main;
    height: calc(100%);

    &.available-chains {
      height: calc(100% - 86px);
    }
  }

  .mat-mdc-dialog-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: sticky;
    bottom: 0;
    background: white;
    border-top: 1px solid $border-default;

    button {
      flex: 1;
    }
  }
}

//CUSTOM OVERLAY BACKDROP

.custom-dialog-backdrop {
  background: rgba(0, 0, 0, 0.4);
}

.warnings-dialog-panel {
  position: absolute !important;
  top: 160px;
  left: 45%;
}

.badge-unlocked-dialog {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 20px 43.5px 0px rgba(17, 16, 33, 0.18);
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
