@use '@angular/material' as mat;

@import 'vars.scss';
@import './styles/pace.scss';
@import './styles/am-components/am-radio-button';
@import './styles/am-components/am-progress-bar';
@import './styles/am-components/am-menu';
@import './styles/am-components/am-sort-header';
@import './styles/am-components/am-expansion-panel';
@import './styles/am-components/am-tabs';
@import './styles/am-components/am-select';
@import './styles/am-components/am-card';
@import './styles/am-components/am-chips';
@import './styles/am-components/am-paginator';
@import './styles/am-components/am-input';
@import './styles/am-components/am-table';
@import './styles/am-components/am-dialog';
@import './styles/am-components/am-button';
@import './styles/am-components/am-snackbar';
@import './styles/am-components/am-form-field';
@import './styles/am-components/am-datepicker';
@import './styles/am-components/am-tooltip';
@import './styles/am-components/am-checkbox';
@import './styles/am-components/am-button-toggle';
@import './styles/am-components/am-slide-toggle';
@import './styles/am-components/am-autocomplete';

@import './styles/reown-components/reown-modal';

@import '@typeform/embed/build/css/popup.css';
@import '@typeform/embed/build/css/widget.css';

@include mat.core();

$waltio-primary: mat.define-palette($waltio-primary-palette);
$waltio-accent: mat.define-palette($waltio-primary-palette);
$waltio-warn: mat.define-palette($waltio-warn-palette);
$waltio-typography: mat.define-typography-config(
  $font-family: 'Roobert',
  $headline-1: mat.define-typography-level(
      $font-family: 'Roobert-SemiBold',
      $font-size: 25px,
    ),
  $headline-2: mat.define-typography-level(
      $font-family: 'Roobert-SemiBold',
      $font-size: 25px,
    ),
  $headline-3: mat.define-typography-level(
      $font-family: 'Roobert-SemiBold',
      $font-size: 25px,
    ),
  $headline-4: mat.define-typography-level(
      $font-family: 'Roobert-SemiBold',
      $font-size: 25px,
    ),
  $headline-5: mat.define-typography-level(
      $font-family: 'Roobert-SemiBold',
      $font-size: 25px,
    ),
  $headline-6: mat.define-typography-level(
      $font-family: 'Roobert-SemiBold',
      $font-size: 20px,
    ),
  $subtitle-1: mat.define-typography-level(
      $font-family: 'Roobert-Medium',
      $font-size: 20px,
    ),
  $subtitle-2: mat.define-typography-level(
      $font-family: 'Roobert-Medium',
      $font-size: 20px,
    ),
  $body-1: mat.define-typography-level(
      $font-size: 14px,
    ),
  $body-2: mat.define-typography-level(
      $font-size: 14px,
    ),
  $caption: mat.define-typography-level(
      $font-size: 12px,
    ),
  $button: mat.define-typography-level(
      $font-family: 'Roobert-Medium',
      $font-size: 14px,
    ),
);

$waltio-theme: mat.define-light-theme(
  (
    color: (
      primary: $waltio-primary,
      accent: $waltio-accent,
      warn: $waltio-warn,
    ),
    typography: $waltio-typography,
  )
);
@include mat.checkbox-density(-1);
@include mat.typography-hierarchy($waltio-typography);
@include mat.all-component-themes($waltio-theme);

* {
  box-sizing: border-box;
  line-height: normal;
  margin: 0;
  padding: 0;
  font-feature-settings: 'liga' off;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
  font-size: 100%;
  scroll-behavior: smooth;
}

h1 {
  margin: 0 !important;
  color: $grey-1;
}

h2,
h3,
h4 {
  margin: 0 !important;
  color: $text-grey-2;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.body-16-bold {
  font-family: 'Roobert-SemiBold', sans-serif;
  font-size: 16px;
  color: $text-grey-2;
}

.body-14-bold {
  font-family: 'Roobert-SemiBold', sans-serif;
  font-size: 14px;
  color: $text-grey-2;
}

.body-14-med {
  font-family: 'Roobert-Medium', sans-serif;
  font-size: 14px;
  color: $text-grey-2;
}

.body-14 {
  font-family: 'Roobert', sans-serif;
  font-size: 14px;
  line-height: 140%;
}

.body-14-light {
  font-family: 'Roobert', sans-serif;
  font-size: 14px;
  line-height: 140%;
  color: $text-grey-3;
}

.body-13-bold {
  font-family: 'Roobert-SemiBold', sans-serif;
  font-size: 13px;
  color: $text-grey-2;
}

.body-13-med {
  font-family: 'Roobert-Medium', sans-serif;
  font-size: 13px;
  color: $grey-2;

  & .select {
    color: $primary-color;
  }
}

.body-13 {
  font-family: 'Roobert', sans-serif;
  font-size: 13px;
  color: $text-grey-2;
}

.body-12-med {
  font-family: 'Roobert-Medium', sans-serif;
  font-size: 12px;
  color: $text-grey-2;
}

.body-12-bold {
  font-family: 'Roobert-SemiBold', sans-serif;
  font-size: 12px;
  color: $grey-1;
}

.body-12 {
  font-family: 'Roobert', sans-serif;
  font-size: 12px;
  color: $text-grey-2;
}

.mat-typography {
  p {
    margin: 0;
  }
}

.body-14-bold-underline {
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
}

.pointer {
  cursor: pointer !important;
}

.pointer-events {
  pointer-events: unset !important;
}

.zoom-in {
  cursor: zoom-in;
}

.error {
  display: inline-block;
  color: $warn-color;
  margin-top: 8px;
}

.error-color {
  color: $warn-color;
  margin: 0 4px;
}

.bold-text {
  font-family: 'Roobert-SemiBold', sans-serif;
}

a {
  color: $primary-color;
}

.custom-link {
  color: #9f93ff;
  text-underline-offset: 2px;
}

.custom-underline {
  font-family: 'Roobert-SemiBold', sans-serif;
  font-size: 14px;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.custom-textarea {
  width: 100%;
  border-radius: 7px;
  border: 1px solid $border-default;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.06);
  resize: none;
  font-family: inherit;
  color: $grey-3;

  &:focus {
    outline: none;
  }
}

.uppercase {
  text-transform: uppercase;
}

// Animations

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  color: #828384;
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@mixin set-margin($vertical-margin, $horizontal-margin) {
  margin: $vertical-margin $horizontal-margin !important;
}

.margin-12 {
  @include set-margin(12px, 12px);
}

.margin-36-auto {
  @include set-margin(36px, auto);
}

.margin-0-auto {
  @include set-margin(0, auto);
}

.margin-auto-0 {
  @include set-margin(auto, 0);
}

.margin-0 {
  @include set-margin(0, 0);
}

@mixin set-width($width) {
  width: $width;
}

.width-100 {
  @include set-width(100%);
}

.width-95 {
  @include set-width(95%);
}

.width-90 {
  @include set-width(90%);
}

.width-80 {
  @include set-width(80%);
}

.width-75 {
  @include set-width(75%);
}

.width-70 {
  @include set-width(70%);
}

.width-60 {
  @include set-width(60%);
}

.width-50 {
  @include set-width(50%);
}

.width-55 {
  @include set-width(55%);
}

.width-40 {
  @include set-width(40%);
}

.width-30 {
  @include set-width(30%);
}

.width-25 {
  @include set-width(25%);
}

.width-20 {
  @include set-width(20%);
}

.width-15 {
  @include set-width(15%);
}

.width-12 {
  @include set-width(12%);
}

.width-8 {
  @include set-width(8%);
}

.f-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.f-row-gap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
}

.f-row-end {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.f-row-between {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.f-column {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.f-column-start {
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
}

.flex-1 {
  flex: 1;
}

.popover {
  background: #363655;
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
  padding: 8px 16px;
  z-index: 5;
}

// Chips
.success-chip {
  background: rgba(31, 211, 173, 0.15);
  border-radius: 4px;
  padding: 4px 8px;
  width: fit-content;
  font-family: 'Roobert-Medium', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #00a985;
}

.info-chip {
  background: rgba(26, 26, 225, 0.1);
  border-radius: 4px;
  padding: 4px 8px;

  font-family: 'Roobert-Medium', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: $primary-color;
}

.m-b-8 {
  margin-bottom: 12px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-12 {
  margin-top: 12px !important;
}

.m-t-14 {
  margin-top: 14px !important;
}

.m-t-16 {
  margin-top: 16px !important;
}

.m-t-22 {
  margin-top: 22px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-33 {
  margin-left: 33px !important;
}

.m-l-4 {
  margin-left: 4px !important;
}

.m-l-36 {
  margin-left: 36px !important;
}

.m-r-16 {
  margin-right: 16px !important;
}

.label {
  border-radius: 3px;
  font-size: 12px;
  padding: 6px;
  line-height: 9px;

  &--success {
    background: $background-success;
    color: $text-success;
  }

  &--warning {
    background: $background-warning;
    color: $text-warning;
  }

  &--error {
    background: $background-error-label;
    color: $text-error-label;
  }
}

.invisible {
  display: none !important;
}

.transparent {
  color: transparent !important;
  background: transparent !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.account-type {
  width: fit-content;
  border-radius: 4px;
  outline: 1px solid $background-grey-3;
  padding: 5px 7px;

  &:hover {
    cursor: pointer;
    outline: 3px solid rgba(80, 59, 255, 0.3);
  }
}

.account-type--default {
  width: fit-content;
  border-radius: 4px;
  outline: 1px solid $background-grey-3;
  padding: 5px 7px;
}

.actions {
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: $background-grey;
  }

  &--active {
    background: rgba(80, 59, 255, 0.1);
  }
}

.badge {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background: $primary-color;
  font-family: 'Roobert-SemiBold', sans-serif;
  font-size: 12px;
  color: white;
  text-align: center;
}

// Tooltip
.info-tooltip {
  cursor: pointer;
  width: 14px;
  height: 12px;
  background: url('/assets/img/icons/info-banner-grey.svg') no-repeat;

  &:hover:not(.info-tooltip--disabled) {
    background: url('/assets/img/icons/info-banner.svg') no-repeat;
  }
}

.info-tooltip--disabled {
  width: 14px;
  height: 12px;
  background: url('/assets/img/icons/info-banner-grey.svg') no-repeat;
}

// Article
.info-button {
  display: inline-block;
  cursor: pointer;
  width: 13px;
  height: 13px;

  background: url('/assets/img/icons/info-button.svg') no-repeat;

  &:hover {
    background: url('/assets/img/icons/info-button-active.svg') no-repeat;
  }
}

// Echarts (!important)
.portfolio-chart-tooltip {
  width: 260px !important;
  border-radius: 7px !important;
  padding: 12px 16px !important;
  display: flex !important;
  flex-flow: column nowrap;
  gap: 4px;

  &__caption {
    width: 7px;
    height: 7px;
    border-radius: 2px;

    &--green {
      background-color: $success-color;
    }

    &--orange {
      background-color: #fb7718;
    }
  }

  &__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &__value {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 4px;
    }
  }

  .gain {
    color: $success-color;
  }

  .loss {
    color: $warn-color;
  }
}

.opportunities-chart-tooltip {
  width: 252px !important;
  border-radius: 7px !important;
  padding: 14px 18px 14px 14px !important;
  display: flex !important;
  flex-flow: column nowrap;
  gap: 4px;

  p.body-12-bold:first-child {
    color: $grey-3;
    margin: 0 0 6px 0 !important;
  }

  &__caption {
    width: 7px;
    height: 7px;
    border-radius: 2px;

    &--purple {
      background-color: #8e80ff;
    }

    &--pink {
      background-color: #e1deff;
    }
  }

  &__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &__label {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 4px;
    }
  }
}

.coin-icon {
  border-radius: 50%;
}

.account-logo-container {
  position: relative;
  display: flex;
  justify-content: flex-end;

  .certified {
    position: absolute;
    right: -6px;
    top: -4px;
  }
}

.account-logo {
  border-radius: 6px;
}

// Wallet Manager
.feedback {
  padding: 5px 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.08);

  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-50 {
  opacity: 0.5;
}

.blur-amount {
  mask-image: linear-gradient(to left, transparent 0%, black 10%);
}

.blur {
  filter: blur(4px);
}

.blur-8 {
  filter: blur(8px);
}

.currency-option {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;

  p.body-14-med {
    color: $grey-2;
  }
}

.mat-drawer-content {
  z-index: unset !important;
  margin: 0 !important;
  padding-left: 250px;
}

.text-warning {
  color: $text-warning !important;
}

// Scoring

.bronze {
  filter: drop-shadow(0px 4px 4px rgba(235, 197, 150, 0.1));
}

.silver {
  filter: drop-shadow(0px 4px 4px rgba(80, 59, 255, 0.05));
}

.gold {
  filter: drop-shadow(0px 4px 4px rgba(249, 218, 150, 0.4));
}
